import React from "react";

const ScrollingMessage = () => {
  return (
    <marquee
      behavior="scroll"
      direction="left"
      style={{
        position: "fixed",
        bottom: "0",
        width: "100%",
        backgroundColor: "#6777EF",
        color: "white",
        padding: "10px 0"
      }}
    >
      সম্মানিত গ্রাহক, শুভ ইংরেজি নববর্ষ ২০২৫। নতুন বছরের শুরুতে বায়ান্নপে
      পরিবারের পক্ষ থেকে জানাই আন্তরিক শুভেচ্ছা ও ভালোবাসা। ২০২৫ হোক আপনার
      ব্যবসার উন্নয়ন, নতুন সম্ভাবনা এবং সাফল্যের এক অনন্য অধ্যায়। আপনার প্রতিটি
      সাফল্যের যাত্রায় আমরা থাকব আস্থার সঙ্গী, প্রতিটি পদক্ষেপে । শুভেচ্ছান্তে,
      বায়ান্নপে টিম
    </marquee>
  );
};

export default ScrollingMessage;
